// notifications.js or notifications.ts
import { defineStore } from 'pinia';

export const useNotificationsStore = defineStore('notifications', {
    state: () => ({
        notifications: [],
    }),
    getters: {
        successNotifications: (state) => state.notifications.filter((notification) => notification.type === 'success'),
        errorNotifications: (state) => state.notifications.filter((notification) => notification.type === 'error'),
        infoNotifications: (state) => state.notifications.filter((notification) => notification.type === 'info'),
    },
    actions: {
        addNotification(notification, autoDismiss = true) {
            notification.timestamp = Date.now();
            notification.autoDismiss = autoDismiss;
            this.notifications.push(notification);
            if (autoDismiss) {
                setTimeout(() => {
                   this.removeNotification(notification);
                }, 15000);
            }
            window.scrollTo(0, 0)
        },
        removeNotification(notification) {
            const index = this.notifications.indexOf(notification);
            if (index !== -1) {
                this.notifications.splice(index, 1);
            }
        },
        addSuccess(message, autoDismiss = true) {
            this.addNotification({ type: 'success', message }, autoDismiss);
        },
        addError(message, autoDismiss = true) {
            this.addNotification({ type: 'error', message }, autoDismiss);
        },
        addInfo(message, autoDismiss = true) {
            this.addNotification({ type: 'info', message }, autoDismiss);
        },
    },
});
